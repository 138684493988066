<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	export let image = null;
</script>

<div class="parallax full-width">
	{#if image}
		<div class="!col-span-full">
			<Image src={image} class="w-full max-w-full" />
		</div>
	{/if}
	<div>
		<slot />
	</div>
</div>

<style>
	.parallax {
		position: relative;
		z-index: -999;
		animation: parallax linear;
		animation-timeline: view(-20% -20%);
		margin-top: -10%;
		margin-bottom: -10%;
	}
	@keyframes parallax {
		from {
			transform: translateY(-33%);
		}
		to {
			transform: translateY(var(--translateY, 33%));
		}
	}
</style>
